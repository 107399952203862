<template>
  <div>
    <!-- Nationalité du promoteur -->
    <!-- <b-form-group
      label-cols-lg="4"
      label="Nationalité Promoteur"
    > -->
    <v-select
      v-model="selectedValue"
      type="text"
      :options="pays"
      label="name"
      :placeholder="placeholder"
      :reduce="(option) => option.name"
    />
    <!-- </b-form-group> -->
  </div>
</template>

<script>
import {
  BCol,
  BRow,
  BButton,
  BCard,
  BCardHeader,
  BCardBody,
  BFormRadioGroup,
  BListGroup,
  BListGroupItem,
  BFormGroup,
  BFormInput,
  BCardImg,
  // BFormCheckbox,
  // BFormRadio,
  // BForm,
  // BCardText,
  BInputGroupPrepend,
  BInputGroup,
  VBPopover,
  BModal,
} from "bootstrap-vue";
import { mapActions } from "vuex";
import vSelect from "vue-select";

import { ValidationProvider, ValidationObserver } from "vee-validate";

import utilsService from "@/services/utils/utils.service";
// imports for validation
// const store = require('store')

export default {
  components: {
    BModal,
    BButton,
    BCol,
    BRow,
    BCardHeader,
    BCardBody,
    BFormRadioGroup,
    BCard,
    BListGroup,
    BListGroupItem,
    BFormGroup,
    BFormInput,
    // BFormCheckbox,
    // BFormRadio,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    BCardImg,
    // BCardText,
    // BForm,
    BInputGroupPrepend,
    BInputGroup,
  },
  directives: {
    "b-popover": VBPopover,
  },
  props: {
    etablissementDetails: null,
    placeholder: "",
    nameOnly: true,
    inputValue: "",
    removeCountries: [],
  },
  data() {
    return {
      pays: [],
      selectedValue: null,
    };
  },
  computed: {},
  watch: {
    selectedValue: {
      immediate: true,
      handler(val, old) {
        // if (this.nameOnly) {
        //   this.$emit('input', val.name)
        //   return
        // }
        this.$emit("input", val);
      },
    },
    inputValue: {
      immediate: true,
      handler(val, old) {
        // if (this.nameOnly) {
        //   this.$emit('input', val.name)
        //   return
        // }
        this.selectedValue = val;
      },
    },
  },

  mounted() {
    const { countries } = utilsService.countriesAndPlaces({
      stringOnly: false,
    });
    let current_countries = countries;
    // filter by removeCountries
    if (this.removeCountries) {
      this.removeCountries.forEach((removed_country) => {
        current_countries = current_countries.filter(
          (country) => country.name !== removed_country
        );
      });
    }

    this.pays = current_countries;
  },
};
</script>

<style lang="scss">
@import "@core/scss/base/themes/bordered-layout.scss";
</style>
