var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('validation-observer',{ref:"FormSectionClientHotels"},[_c('b-card',{staticClass:"pl-5 pr-5"},[_c('b-card-header'),_vm._v(" "+_vm._s(_vm.infosEnregistrementClients)+" "),_c('br'),_vm._v(" "),_c('br'),_vm._v(" "+_vm._s(_vm.inputData)+" "),_c('b-form-group',{attrs:{"label-cols-md":"2","label":"NPI"}},[_c('validation-provider',{attrs:{"rules":"length:10"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"Numéro personnel d'identification"},model:{value:(_vm.infosEnregistrementClients.npi),callback:function ($$v) {_vm.$set(_vm.infosEnregistrementClients, "npi", $$v)},expression:"infosEnregistrementClients.npi"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-row',[_c('b-col',{attrs:{"md":"5"}},[_c('b-form-group',{attrs:{"label-cols-md":"5","label":"Date d'arrivée"}},[_c('validation-provider',{attrs:{"rules":"required|beforeOrToday"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{staticClass:"form-control",attrs:{"lang":"fr-CA","type":"date","placeholder":"Date d'arrivée"},model:{value:(_vm.infosEnregistrementClients.dateArrivee),callback:function ($$v) {_vm.$set(_vm.infosEnregistrementClients, "dateArrivee", $$v)},expression:"infosEnregistrementClients.dateArrivee"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"5"}},[_c('b-form-group',{attrs:{"label-cols-md":"4","label":"Heure"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('flat-pickr',{staticClass:"form-control",attrs:{"placeholder":"Heure","config":{
                   enableTime: true,
                   noCalendar: true,
                   dateFormat: 'H:i',
                 }},model:{value:(_vm.infosEnregistrementClients.heureArrivee),callback:function ($$v) {_vm.$set(_vm.infosEnregistrementClients, "heureArrivee", $$v)},expression:"infosEnregistrementClients.heureArrivee"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"2"}},[_c('b-form-group',{attrs:{"label-cols-md":"6","label":"Chambre"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                 var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"K12"},model:{value:(_vm.infosEnregistrementClients.numeroChambre),callback:function ($$v) {_vm.$set(_vm.infosEnregistrementClients, "numeroChambre", $$v)},expression:"infosEnregistrementClients.numeroChambre"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-form-group',{attrs:{"label-cols-md":"2","label":"Date de départ"}},[_c('validation-provider',{attrs:{"rules":{required: _vm.required, date_between: [_vm.infosEnregistrementClients.dateArrivee, null, false]}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                 var errors = ref.errors;
return [_c('b-form-input',{staticClass:"form-control",attrs:{"lang":"fr-FR","type":"date","placeholder":"Date de départ"},model:{value:(_vm.infosEnregistrementClients.dateDepart),callback:function ($$v) {_vm.$set(_vm.infosEnregistrementClients, "dateDepart", $$v)},expression:"infosEnregistrementClients.dateDepart"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label-cols-md":"2","label":"Nom :"}},[_c('validation-provider',{attrs:{"rules":"required|alpha"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                 var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"Nom"},model:{value:(_vm.infosEnregistrementClients.lastname),callback:function ($$v) {_vm.$set(_vm.infosEnregistrementClients, "lastname", $$v)},expression:"infosEnregistrementClients.lastname"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label-cols-md":"2","label":"Prénoms :"}},[_c('validation-provider',{attrs:{"rules":"required|alpha"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                 var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"Prénoms"},model:{value:(_vm.infosEnregistrementClients.firstname),callback:function ($$v) {_vm.$set(_vm.infosEnregistrementClients, "firstname", $$v)},expression:"infosEnregistrementClients.firstname"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label-cols-md":"2","label":"Sexe :"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                 var errors = ref.errors;
return [_c('b-form-radio-group',{staticClass:"demo-inline-spacing mb-1 ml-1",attrs:{"options":_vm.sexe,"value-field":"value","text-field":"text","disabled-field":"disabled"},model:{value:(_vm.infosEnregistrementClients.sexe),callback:function ($$v) {_vm.$set(_vm.infosEnregistrementClients, "sexe", $$v)},expression:"infosEnregistrementClients.sexe"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),(_vm.infosEnregistrementClients.sexe === 'Féminin')?_c('b-form-group',{attrs:{"label-cols-md":"2","label":"Nom de jeune fille :","rules":"alpha"}},[_c('b-form-input',{attrs:{"placeholder":"Nom de la jeune fille"},model:{value:(_vm.infosEnregistrementClients.maidenname),callback:function ($$v) {_vm.$set(_vm.infosEnregistrementClients, "maidenname", $$v)},expression:"infosEnregistrementClients.maidenname"}})],1):_vm._e(),_c('b-form-group',{attrs:{"label-cols-md":"2","label":"Date de naissance :"}},[_c('validation-provider',{attrs:{"rules":"required|beforeOrToday|valideBirthdate"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                 var errors = ref.errors;
return [_c('b-form-input',{staticClass:"form-control",attrs:{"placeholder":"Date de naissance","lang":"fr-FR","type":"date"},model:{value:(_vm.infosEnregistrementClients.dateNaissance),callback:function ($$v) {_vm.$set(_vm.infosEnregistrementClients, "dateNaissance", $$v)},expression:"infosEnregistrementClients.dateNaissance"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label-cols-md":"2","label":"Lieu de naissance"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                 var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"Lieu de naissance"},model:{value:(_vm.infosEnregistrementClients.lieuNaissance),callback:function ($$v) {_vm.$set(_vm.infosEnregistrementClients, "lieuNaissance", $$v)},expression:"infosEnregistrementClients.lieuNaissance"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label-cols-md":"2","label":"Nationalité :"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                 var errors = ref.errors;
return [_c('SelectCountriesList',{attrs:{"inputValue":_vm.infosEnregistrementClients.nationalite,"placeholder":'Sélectionner le pays de votre  nationalité'},model:{value:(_vm.infosEnregistrementClients.nationalite),callback:function ($$v) {_vm.$set(_vm.infosEnregistrementClients, "nationalite", $$v)},expression:"infosEnregistrementClients.nationalite"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label-cols-md":"2","label":"Lieu de résidence habituelle :"}},[_c('b-form-group',{attrs:{"label-cols-md":"2","label":"Adresse"}},[_c('b-form-input',{attrs:{"placeholder":"L'adresse du lieu de résidence"},model:{value:(_vm.infosEnregistrementClients.lieuResidence.adresse),callback:function ($$v) {_vm.$set(_vm.infosEnregistrementClients.lieuResidence, "adresse", $$v)},expression:"infosEnregistrementClients.lieuResidence.adresse"}})],1),_c('b-row',[_c('b-col',{staticClass:"md-6"},[_c('b-form-group',{attrs:{"label-cols-md":"4","label":"Code Postal"}},[_c('b-form-input',{attrs:{"placeholder":"Le code postal du lieu de résidence"},model:{value:(_vm.infosEnregistrementClients.lieuResidence.bp),callback:function ($$v) {_vm.$set(_vm.infosEnregistrementClients.lieuResidence, "bp", $$v)},expression:"infosEnregistrementClients.lieuResidence.bp"}})],1)],1),_c('b-col',{staticClass:"md-6"},[_c('b-form-group',{attrs:{"label-cols-md":"3","label":"Ville/Commune :"}},[_c('b-form-input',{attrs:{"placeholder":"Ville du lieu de résidence"},model:{value:(
                   _vm.infosEnregistrementClients.lieuResidence.villeCommune
                 ),callback:function ($$v) {_vm.$set(_vm.infosEnregistrementClients.lieuResidence, "villeCommune", $$v)},expression:"\n                   infosEnregistrementClients.lieuResidence.villeCommune\n                 "}})],1)],1)],1),_c('b-row',[_c('b-col',{staticClass:"md-6"},[_c('b-form-group',{attrs:{"label-cols-md":"4","label":"Département"}},[_c('b-form-input',{attrs:{"placeholder":"Département du lieu de résidence"},model:{value:(_vm.infosEnregistrementClients.lieuResidence.departement),callback:function ($$v) {_vm.$set(_vm.infosEnregistrementClients.lieuResidence, "departement", $$v)},expression:"infosEnregistrementClients.lieuResidence.departement"}})],1)],1),_c('b-col',{staticClass:"md-6"},[_c('b-form-group',{attrs:{"label-cols-md":"3","label":"Pays :"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                 var errors = ref.errors;
return [_c('SelectCountriesList',{attrs:{"inputValue":_vm.infosEnregistrementClients.lieuResidence.pays,"placeholder":"Pays du lieu de résidence"},model:{value:(_vm.infosEnregistrementClients.lieuResidence.pays),callback:function ($$v) {_vm.$set(_vm.infosEnregistrementClients.lieuResidence, "pays", $$v)},expression:"infosEnregistrementClients.lieuResidence.pays"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1),_c('b-form-group',{attrs:{"label-cols-md":"2","label":"Téléphone"}},[_c('validation-provider',{attrs:{"rules":"length:8"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                 var errors = ref.errors;
return [_c('b-form-input',{directives:[{name:"phonenumber-field",rawName:"v-phonenumber-field"}],attrs:{"placeholder":"Téléphone"},model:{value:(_vm.infosEnregistrementClients.telephone),callback:function ($$v) {_vm.$set(_vm.infosEnregistrementClients, "telephone", $$v)},expression:"infosEnregistrementClients.telephone"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label-cols-md":"2","label":"Adresse mail"}},[_c('validation-provider',{attrs:{"rules":"email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                 var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"Adresse mail"},model:{value:(_vm.infosEnregistrementClients.email),callback:function ($$v) {_vm.$set(_vm.infosEnregistrementClients, "email", $$v)},expression:"infosEnregistrementClients.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label-cols-md":"2","label":"Catégorie socio professionnelle :"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                 var errors = ref.errors;
return [_c('b-form-radio-group',{attrs:{"options":_vm.categorie,"stacked":"","value-field":"value","text-field":"text","disabled-field":"disabled"},model:{value:(_vm.infosEnregistrementClients.categorieSocioPro),callback:function ($$v) {_vm.$set(_vm.infosEnregistrementClients, "categorieSocioPro", $$v)},expression:"infosEnregistrementClients.categorieSocioPro"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])}),(_vm.infosEnregistrementClients.categorieSocioPro === 'true')?_c('b-form-group',{staticClass:"mt-1"},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                 var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"Autres catégories socio professionnelles"},model:{value:(_vm.categorieSocioProAutres),callback:function ($$v) {_vm.categorieSocioProAutres=$$v},expression:"categorieSocioProAutres"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3440280123)})],1):_vm._e()],1),_c('b-form-group',{attrs:{"label-cols-md":"2","label":"Mode principal de transport à l'intérieur du Bénin :"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                 var errors = ref.errors;
return [_c('b-form-radio-group',{staticClass:"demo-inline-spacing mb-1 ml-1",attrs:{"options":_vm.modePrincipalTransport,"value-field":"value","text-field":"text","disabled-field":"disabled"},model:{value:(_vm.infosEnregistrementClients.modeTransportInterieurPays),callback:function ($$v) {_vm.$set(_vm.infosEnregistrementClients, "modeTransportInterieurPays", $$v)},expression:"infosEnregistrementClients.modeTransportInterieurPays"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label-cols-md":"2","label":"Motif principal du voyage :"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                 var errors = ref.errors;
return [_c('b-form-radio-group',{attrs:{"options":_vm.motifPrincipalVoyage,"stacked":"","value-field":"value","text-field":"text","disabled-field":"disabled"},model:{value:(_vm.infosEnregistrementClients.motifPrincipalVoyage),callback:function ($$v) {_vm.$set(_vm.infosEnregistrementClients, "motifPrincipalVoyage", $$v)},expression:"infosEnregistrementClients.motifPrincipalVoyage"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])}),(_vm.infosEnregistrementClients.motifPrincipalVoyage === 'true')?_c('b-form-group',{staticClass:"mt-1"},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                 var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"Autres motifs principaux de voyage"},model:{value:(_vm.motifPrincipalVoyageAutres),callback:function ($$v) {_vm.motifPrincipalVoyageAutres=$$v},expression:"motifPrincipalVoyageAutres"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2033895713)})],1):_vm._e()],1),_c('b-form-group',{attrs:{"label-cols-md":"2","label":"Allez-vous recevoir une rémunération d'une entité du pays/Lieu visité durant votre séjour ?"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                 var errors = ref.errors;
return [_c('b-form-radio-group',{staticClass:"demo-inline-spacing mb-1 ml-1",attrs:{"options":_vm.question,"value-field":"value","text-field":"text","disabled-field":"disabled"},model:{value:(_vm.infosEnregistrementClients.possibiliteRemuneration),callback:function ($$v) {_vm.$set(_vm.infosEnregistrementClients, "possibiliteRemuneration", $$v)},expression:"infosEnregistrementClients.possibiliteRemuneration"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label-cols-md":"2","label":"Piece d'identité :"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                 var errors = ref.errors;
return [_c('b-form-radio-group',{staticClass:"demo-inline-spacing mb-1 ml-1",attrs:{"options":_vm.pieceIdentite,"value-field":"value","text-field":"text","disabled-field":"disabled"},model:{value:(_vm.infosEnregistrementClients.pieceIdentite.type),callback:function ($$v) {_vm.$set(_vm.infosEnregistrementClients.pieceIdentite, "type", $$v)},expression:"infosEnregistrementClients.pieceIdentite.type"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-row',[_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label-cols-md":"6","label":"N° de la pièce"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                 var errors = ref.errors;
return [_c('b-form-input',{directives:[{name:"digits-only",rawName:"v-digits-only",value:({length: 16}),expression:"{length: 16}"}],attrs:{"type":"number","placeholder":"Numéro de la pièce"},model:{value:(_vm.infosEnregistrementClients.pieceIdentite.numeroPiece),callback:function ($$v) {_vm.$set(_vm.infosEnregistrementClients.pieceIdentite, "numeroPiece", _vm._n($$v))},expression:"infosEnregistrementClients.pieceIdentite.numeroPiece"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"5"}},[_c('b-form-group',{attrs:{"label-cols-md":"4","label":"Délivrée le"}},[_c('validation-provider',{attrs:{"rules":"required|beforeOrToday"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                 var errors = ref.errors;
return [_c('b-form-input',{staticClass:"form-control",attrs:{"placeholder":"Délivrée le","lang":"fr-FR","type":"date"},model:{value:(_vm.infosEnregistrementClients.pieceIdentite.delivreLe),callback:function ($$v) {_vm.$set(_vm.infosEnregistrementClients.pieceIdentite, "delivreLe", $$v)},expression:"infosEnregistrementClients.pieceIdentite.delivreLe"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',{attrs:{"label-cols-md":"5","label":"à"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                 var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"à"},model:{value:(_vm.infosEnregistrementClients.pieceIdentite.a),callback:function ($$v) {_vm.$set(_vm.infosEnregistrementClients.pieceIdentite, "a", $$v)},expression:"infosEnregistrementClients.pieceIdentite.a"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1),(_vm.showEditionButtons)?_c('b-row',[_c('b-col',{staticClass:"my-2 text-right",attrs:{"cols":"12"}},[_c('div',{},[_c('b-button',{attrs:{"variant":"outline-secondary"},on:{"click":_vm.onCancel}},[_vm._v(" Fermer ")]),_c('b-button',{attrs:{"variant":"primary"},on:{"click":_vm.submitForm}},[_vm._v(" Modifier ")])],1)])],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }