<template>
  <div>
    <validation-observer ref="FormSectionClientHotels">
     <b-card class="pl-5 pr-5">
        <!-- head -->
        <b-card-header>
          <!-- <b-row no-gutters class="w-100">
            <b-col md="12">
              <div class="d-flex justify-content-end">
                <div>
                  <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    v-b-popover.hover.focus="
                      '⚪️ Cette fiche concerne l\'enregistrement des clients dans les hôtels et établissements d\'hébergement assimilés  ⚪️ La fiche peut être remplie par les clients ou par les agents des hôtels.'
                    "
                    size="lg"
                    variant="outline-primary"
                    class="btn-icon rounded-circle"
                    title="Information"
                  >
                    <feather-icon icon="BellIcon" />
                  </b-button>
                </div>
              </div>
            </b-col>
            <b-col md="2">
              <b-card-img
                :src="
                  require('@/assets/images/illustration/Globalization-pana.png')
                "
                alt="Image"
                class="rounded-0 my-2"
              />
            </b-col>
            <b-col
              md="10"
              class="d-flex align-items-center justify-content-center"
            >
              <div>
                <br>
                <h4 class="m-auto text-center uppercase">
                  {{ formTitle }}
                </h4>
              </div>
            </b-col>
          </b-row> -->
        </b-card-header>
        <!-- Liste des hôtels -->
        <!-- <b-form-group
          label-cols-md="2"
          label="Nom de l'établissement"
        >
          <validation-provider
            v-slot="{ errors }"
            rules="required"
          >
            <v-select
              v-model="infosEnregistrementClients.etablissement"
              :reduce="(option) => option.nomEtablissement"
              type="text"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="listes_hotels"
              label="nomEtablissement"
              value="_id"
              placeholder="Veuillez sélectionner l'hôtel"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group> -->

        {{ infosEnregistrementClients }} <br> <br> {{ inputData }}
        <!-- Npi -->
        <b-form-group
          label-cols-md="2"
          label="NPI"
        >
          <validation-provider
            v-slot="{ errors }"
            rules="length:10"
          >
            <b-form-input
              v-model="infosEnregistrementClients.npi"
              placeholder="Numéro personnel d'identification"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <!-- Date d'arrivée & heure & chambre -->
        <b-row>
          <!-- Date d'arrivée -->
          <b-col md="5">
            <b-form-group
              label-cols-md="5"
              label="Date d'arrivée"
            >
              <validation-provider
                v-slot="{ errors }"
                rules="required|beforeOrToday"
              >
                <b-form-input
                  v-model="infosEnregistrementClients.dateArrivee"
                  lang="fr-CA"
                  type="date"
                  class="form-control"
                  placeholder="Date d'arrivée"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- Heure -->
          <b-col md="5">
            <b-form-group
              label-cols-md="4"
              label="Heure"
            >
              <validation-provider
                v-slot="{ errors }"
                rules="required"
              >
                <flat-pickr
                  v-model="infosEnregistrementClients.heureArrivee"
                  class="form-control"
                  placeholder="Heure"
                  :config="{
                    enableTime: true,
                    noCalendar: true,
                    dateFormat: 'H:i',
                  }"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- Chambre n° -->
          <b-col md="2">
            <b-form-group
              label-cols-md="6"
              label="Chambre"
            >
              <validation-provider
                v-slot="{ errors }"
                rules="required"
              >
                <b-form-input
                  v-model="infosEnregistrementClients.numeroChambre"
                  placeholder="K12"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>

        <!-- Date de départ -->
        <b-form-group
          label-cols-md="2"
          label="Date de départ"
        >
          <validation-provider
            v-slot="{ errors }"
            :rules="{required, date_between: [infosEnregistrementClients.dateArrivee, null, false]}"
          >

            <b-form-input
              v-model="infosEnregistrementClients.dateDepart"
              lang="fr-FR"
              type="date"
              class="form-control"
              placeholder="Date de départ"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <!-- Nom/Name -->
        <b-form-group
          label-cols-md="2"
          label="Nom :"
        >
          <validation-provider
            v-slot="{ errors }"
            rules="required|alpha"
          >
            <b-form-input
              v-model="infosEnregistrementClients.lastname"
              placeholder="Nom"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <!-- Prénoms/First Names -->
        <b-form-group
          label-cols-md="2"
          label="Prénoms :"
        >
          <validation-provider
            v-slot="{ errors }"
            rules="required|alpha"
          >
            <b-form-input
              v-model="infosEnregistrementClients.firstname"
              placeholder="Prénoms"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

       
        <!-- Sexe -->
        <b-form-group
          label-cols-md="2"
          label="Sexe :"
        >
          <validation-provider
            v-slot="{ errors }"
            rules="required"
          >
            <b-form-radio-group
              v-model="infosEnregistrementClients.sexe"
              :options="sexe"
              class="demo-inline-spacing mb-1 ml-1"
              value-field="value"
              text-field="text"
              disabled-field="disabled"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

 <!-- Nom de jeune fille/Maiden Names -->
        <b-form-group
          v-if="infosEnregistrementClients.sexe === 'Féminin'"
          label-cols-md="2"
          label="Nom de jeune fille :"
          rules="alpha"
        >
          <b-form-input
            v-model="infosEnregistrementClients.maidenname"
            placeholder="Nom de la jeune fille"
          />
        </b-form-group>


        <!-- Date de naissance/Date of birth day month year -->
        <b-form-group
          label-cols-md="2"
          label="Date de naissance :"
        >
          <validation-provider
            v-slot="{ errors }"
            rules="required|beforeOrToday|valideBirthdate"
          >
            <b-form-input
              v-model="infosEnregistrementClients.dateNaissance"
              class="form-control"
              placeholder="Date de naissance"
              lang="fr-FR"
              type="date"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <!-- Lieu de naissance/Place of birth -->
        <b-form-group
          label-cols-md="2"
          label="Lieu de naissance"
        >
          <validation-provider
            v-slot="{ errors }"
            rules="required"
          >
            <b-form-input
              v-model="infosEnregistrementClients.lieuNaissance"
              placeholder="Lieu de naissance"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <!-- Nationalite/Nationality -->
        <b-form-group
          label-cols-md="2"
          label="Nationalité :"
        >
          <validation-provider
            v-slot="{ errors }"
            rules="required"
          >
            <SelectCountriesList
              v-model="infosEnregistrementClients.nationalite"
              :inputValue="infosEnregistrementClients.nationalite"
              :placeholder="'Sélectionner le pays de votre  nationalité'"
            />
            <!-- <v-select
              v-model="infosEnregistrementClients.nationalite"
              type="text"
              :options="pays"
              placeholder="Sélectionner le pays de votre nationalité"
            /> -->
            <!-- <b-form-input
              v-model="infosEnregistrementClients.nationalite"
              placeholder="Nationalité"
            /> -->
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <!-- Lieu de résidence habituelle -->
        <b-form-group
          label-cols-md="2"
          label="Lieu de résidence habituelle :"
        >
          <!-- Lieu de résidence habituelle (adresse) -->
          <b-form-group
            label-cols-md="2"
            label="Adresse"
          >
            <!-- <validation-provider
              v-slot="{ errors }"
              rules="required"
            > -->
            <b-form-input
              v-model="infosEnregistrementClients.lieuResidence.adresse"
              placeholder="L'adresse du lieu de résidence"
            />
            <!-- <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider> -->
          </b-form-group>
          <b-row>
            <!-- Lieu de résidence habituelle (code postal) -->
            <b-col class="md-6">
              <b-form-group
                label-cols-md="4"
                label="Code Postal"
              >
                <b-form-input
                  v-model="infosEnregistrementClients.lieuResidence.bp"
                  placeholder="Le code postal du lieu de résidence"
                />
              </b-form-group>
            </b-col>

            <!-- Lieu de résidence habituelle (Ville/Commune) -->
            <b-col class="md-6">
              <b-form-group
                label-cols-md="3"
                label="Ville/Commune :"
              >
                <!-- <validation-provider
                  v-slot="{ errors }"
                  rules="required"
                > -->

                <b-form-input
                  v-model="
                    infosEnregistrementClients.lieuResidence.villeCommune
                  "
                  placeholder="Ville du lieu de résidence"
                />
                <!-- <v-select
                    v-model="infosEnregistrementClients.lieuResidence.villeCommune"
                    :reduce="(option) => option.name"
                    type="text"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="ville_commune"
                    label="name"
                    placeholder=" Ville ou Commune du lieu de résidence"
                  /> -->
                <!-- <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider> -->
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <!-- bof: {{infosEnregistrementClients.lieuResidence.departement}} -->
            <!-- Lieu de résidence habituelle (Département) -->
            <b-col class="md-6">
              <b-form-group
                label-cols-md="4"
                label="Département"
              >
                <b-form-input
                  v-model="infosEnregistrementClients.lieuResidence.departement"

                  placeholder="Département du lieu de résidence"
                />
                <!-- <v-select
                  v-model="infosEnregistrementClients.lieuResidence.departement"
                  type="text"
                  :options="departement"
                  placeholder="Département du lieu de résidence"
                /> -->
              </b-form-group>
            </b-col>

            <!-- Lieu de résidence habituelle (Pays) -->
            <b-col class="md-6">
              <b-form-group
                label-cols-md="3"
                label="Pays :"
              >
                <validation-provider
                  v-slot="{ errors }"
                  rules="required"
                >
                  <SelectCountriesList
                    v-model="infosEnregistrementClients.lieuResidence.pays"
                    :inputValue="infosEnregistrementClients.lieuResidence.pays"
                    placeholder="Pays du lieu de résidence"
                  />
                  <!-- <v-select
                    v-model="infosEnregistrementClients.lieuResidence.pays"
                    type="text"
                    :options="pays"
                    label="name"
                    placeholder="Pays du lieu de résidence"
                  /> -->
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </b-form-group>

        <!-- Telephone -->
        <b-form-group
          label-cols-md="2"
          label="Téléphone"
        >
          <validation-provider
            v-slot="{ errors }"
            rules="length:8"
          >
            <b-form-input
              v-model="infosEnregistrementClients.telephone"
              placeholder="Téléphone"
                              v-phonenumber-field

            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <!-- Adresse mail -->
        <b-form-group
          label-cols-md="2"
          label="Adresse mail"
        >
          <validation-provider
            v-slot="{ errors }"
            rules="email"
          >
            <b-form-input
              v-model="infosEnregistrementClients.email"
              placeholder="Adresse mail"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <!-- Catégorie socio professionnelle -->
        <b-form-group
          label-cols-md="2"
          label="Catégorie socio professionnelle :"
        >
          <validation-provider
            v-slot="{ errors }"
            rules="required"
          >
            <b-form-radio-group
              v-model="infosEnregistrementClients.categorieSocioPro"
              :options="categorie"
              stacked
              value-field="value"
              text-field="text"
              disabled-field="disabled"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
          <b-form-group
            v-if="infosEnregistrementClients.categorieSocioPro === 'true'"
            class="mt-1"
          >
            <validation-provider
              v-slot="{ errors }"
              rules="required"
            >
              <b-form-input
                v-model="categorieSocioProAutres"
                placeholder="Autres catégories socio professionnelles"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-form-group>

        <!-- Mode principal de transport à l'intérieur du Bénin -->
        <b-form-group
          label-cols-md="2"
          label="Mode principal de transport à l'intérieur du Bénin :"
        >
          <validation-provider
            v-slot="{ errors }"
            rules="required"
          >
            <b-form-radio-group
              v-model="infosEnregistrementClients.modeTransportInterieurPays"
              :options="modePrincipalTransport"
              class="demo-inline-spacing mb-1 ml-1"
              value-field="value"
              text-field="text"
              disabled-field="disabled"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <!-- Motif principal du voyage/Purpose of the trip -->
        <b-form-group
          label-cols-md="2"
          label="Motif principal du voyage :"
        >
          <validation-provider
            v-slot="{ errors }"
            rules="required"
          >
            <b-form-radio-group
              v-model="infosEnregistrementClients.motifPrincipalVoyage"
              :options="motifPrincipalVoyage"
              stacked
              value-field="value"
              text-field="text"
              disabled-field="disabled"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
          <b-form-group
            v-if="infosEnregistrementClients.motifPrincipalVoyage === 'true'"
            class="mt-1"
          >
            <validation-provider
              v-slot="{ errors }"
              rules="required"
            >
              <b-form-input
                v-model="motifPrincipalVoyageAutres"
                placeholder="Autres motifs principaux de voyage"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-form-group>

        <!-- Question -->
        <b-form-group
          label-cols-md="2"
          label="Allez-vous recevoir une rémunération d'une entité du pays/Lieu visité durant votre séjour ?"
        >
          <validation-provider
            v-slot="{ errors }"
            rules="required"
          >
            <b-form-radio-group
              v-model="infosEnregistrementClients.possibiliteRemuneration"
              :options="question"
              class="demo-inline-spacing mb-1 ml-1"
              value-field="value"
              text-field="text"
              disabled-field="disabled"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <!-- Piece d'identite -->
        <b-form-group
          label-cols-md="2"
          label="Piece d'identité :"
        >
          <validation-provider
            v-slot="{ errors }"
            rules="required"
          >
            <b-form-radio-group
              v-model="infosEnregistrementClients.pieceIdentite.type"
              :options="pieceIdentite"
              class="demo-inline-spacing mb-1 ml-1"
              value-field="value"
              text-field="text"
              disabled-field="disabled"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <!-- N° de la piece & delivrée le & à -->
        <b-row>
          <!-- N° de la pièce -->
          <b-col md="4">
            <b-form-group
              label-cols-md="6"
              label="N° de la pièce"
            >
              <validation-provider
                v-slot="{ errors }"
                rules="required"
              >
                <b-form-input
                  v-model.number="infosEnregistrementClients.pieceIdentite.numeroPiece"
                  type="number"
                  v-digits-only="{length: 16}"
                  placeholder="Numéro de la pièce"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- Délivrée le -->
          <b-col md="5">
            <b-form-group
              label-cols-md="4"
              label="Délivrée le"
            >
              <validation-provider
                v-slot="{ errors }"
                rules="required|beforeOrToday"
              >

                <b-form-input
                  v-model="infosEnregistrementClients.pieceIdentite.delivreLe"
                  class="form-control"
                  placeholder="Délivrée le"
                  lang="fr-FR"
                  type="date"
                />

                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- A -->
          <b-col md="3">
            <b-form-group
              label-cols-md="5"
              label="à"
            >
              <validation-provider
                v-slot="{ errors }"
                rules="required"
              >

                <b-form-input
                  v-model="infosEnregistrementClients.pieceIdentite.a"
                  placeholder="à"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>

        <!-- Signature -->
        <!-- <b-form-group class="mt-4">
          <h4 class="text-center">
            Signature
          </h4>
          <validation-provider
            #default="{ errors }"
            rules="required"
          >
            <b-form-file
              v-model="infosEnregistrementClients.signature"
              class="mb-2"
              placeholder="Choisissez signature ou glisser et déposer le ici ..."
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group> -->
      </b-card>

      <b-row v-if="showEditionButtons">
        <b-col
          cols="12"
          class="my-2 text-right"
        >
          <div class="">
            <b-button
              variant="outline-secondary"
              @click="onCancel"
            >
              Fermer
            </b-button>
            <b-button
              variant="primary"
              @click="submitForm"
            >
              Modifier
            </b-button>
          </div>
        </b-col>
      </b-row>
    </validation-observer>
  </div>
</template>

<script>
import {
  BButton,
  BCard,
  BCol,
  BRow,
  BFormInput,
  BFormGroup,
  BFormRadioGroup,
  BFormFile,
  BCardHeader,
  BCardImg,
  VBPopover,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import flatPickr from 'vue-flatpickr-component'
import vSelect from 'vue-select'
// import axios from 'axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import { mapGetters, mapActions } from 'vuex'
import SelectCountriesList from '@/components/SelectCountriesList.vue'
import utilsService from '@/services/utils/utils.service'

// store modules and vuex utilities
import etablissementsStroreModule from '@/store/etablissements'
import paramsStoreModule from '@/store/params'
import utilsStoreModule from '@/store/utils'
import formulaireStoreModule from '@/store/formulaire'

import {
  registerStoreModule,
  unregisterStoreModule,
} from '@/helpers/vuex-utils'

export default {
  components: {
    BButton,
    BCard,
    BCardHeader,
    BCol,
    BRow,
    BFormGroup,
    BFormInput,
    BFormRadioGroup,
    BFormFile,
    flatPickr,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    BCardImg,
    SelectCountriesList,
  },

  directives: {
    Ripple,
    'b-popover': VBPopover,
  },

  props: {
    inputData: {
      type: Object,
      default: () => null,
    },
    showEditionButtons: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const requiredStoreModules = [
      { path: 'etablissements', module: etablissementsStroreModule },
      { path: 'params', module: paramsStoreModule },
      { path: 'utils', module: utilsStoreModule },
      { path: 'formulaire', module: formulaireStoreModule },
    ]
    // Register module
    registerStoreModule(requiredStoreModules)

    return { requiredStoreModules }
  },
  data() {
    return {
      sexe: [
        { text: 'Masculin', value: 'Masculin' },
        { text: 'Féminin', value: 'Féminin' },
      ],
      categorie: [
        { text: 'Libéral/indépendant', value: 'Libéral/indépendant' },
        { text: 'Employé/salarié', value: 'Employé/salarié' },
        { text: 'Retraité', value: 'Retraité' },
        { text: 'Autres (A préciser)', value: 'true' },
      ],
      motifPrincipalVoyage: [
        {
          text: 'Affaires et motifs professionnels',
          value: 'Affaires et motifs professionnels',
        },
        {
          text: 'Vacances, loisirs et détente',
          value: 'Vacances, loisirs et détente',
        },
        {
          text: 'Visites à des parents ou à des amis',
          value: 'Visites à des parents ou à des amis',
        },
        { text: 'Éducation et formation', value: 'Éducation et formation' },
        { text: 'Santé et soins médicaux', value: 'Santé et soins médicaux' },
        { text: 'Religion ou pèlerinage', value: 'Religion ou pèlerinage' },
        { text: 'Achats', value: 'Achats' },
        { text: 'Transit', value: 'Transit' },
        { text: 'Autres (A préciser)', value: 'true' },
      ],
      modePrincipalTransport: [
        { text: 'Aérien', value: 'Aérien' },
        { text: 'Terrestre', value: 'Terrestre' },
        { text: 'Par eau', value: 'Par eau' },
      ],
      question: [
        { text: 'Oui', value: true },
        { text: 'Non', value: false },
      ],
      pieceIdentite: [
        { text: "Carte d'identité", value: "Carte d'identité" },
        { text: 'Passeport', value: 'Passeport' },
        { text: 'Autre', value: 'Autre' },
      ],

      ville_commune: [],

      departement: [
        { name: 'Littoral' },
        { name: 'Couffo' },
        { name: 'Borgou' },
        { name: 'Oueme' },
      ],

      pays: [],

      listes_hotels: [],

      infosEnregistrementClients: {
        etablissement: '',
        npi: '',
        dateArrivee: '',
        heureArrivee: '',
        numeroChambre: '',
        dateDepart: '',
        lastname: '',
        firstname: '',
        maidenname: '',
        sexe: '',
        dateNaissance: '',
        lieuNaissance: '',
        nationalite: '',
        lieuResidence: {
          adresse: '',
          bp: '',
          villeCommune: '',
          departement: '',
          pays: '',
        },
        telephone: '',
        email: '',
        categorieSocioPro: '',
        // autrescategorie: null,
        modeTransportInterieurPays: '',
        motifPrincipalVoyage: '',
        // autresMotifPrincipalVoyage: null,
        possibiliteRemuneration: null,
        pieceIdentite: {
          numeroPiece: '',
          delivreLe: '',
          a: '',
          type: '',
        },
        // secteur: 'Hotel',
        // pieceIdentite: "Carte d'identité",
        // signature: null,
      },
      categorieSocioProAutres: null,
      motifPrincipalVoyageAutres: null,

      infosEnregistrementClientsFictif: {
        etablissement: 'The Paradise',
        npi: '5135135151',
        dateArrivee: '2022-08-22',
        heureArrivee: '12:00',
        numeroChambre: '23',
        dateDepart: '2022-08-29',
        lastname: 'TestNom',
        firstname: 'TestPrénom',
        maidenname: 'TestJeuneFille',
        sexe: 'Masculin',
        dateNaissance: '2022-08-01',
        lieuNaissance: 'Cotonou',
        nationalite: 'Béninoise',
        lieuResidence: {
          adresse: 'Agla',
          bp: 229,
          villeCommune: 'Cotonou, Littoral',
          departement: 'Littoral',
          pays: 'Bénin',
        },
        telephone: '955587970',
        email: 'test@gmail.com',
        categorieSocioPro: 'Employé/salarié',
        // autrescategorie: null,
        modeTransportInterieurPays: 'Terrestre',
        motifPrincipalVoyage: 'Vacances, loisirs et détente',
        // autresMotifPrincipalVoyage: null,
        possibiliteRemuneration: true,
        pieceIdentite: {
          numeroPiece: 651651651,
          delivrePar: '2022-07-23',
          a: 'Parakou',
          type: "Carte d'identité",
        },
        // secteur: 'Hotel',
        // pieceIdentite: "Carte d'identité",
        // signature: null,
      },

      name: '',
      required,
    }
  },

  watch: {
    $route: {
      immediate: true,
      async handler(val, old) {
        // await this.action_fetchTypesRoles()
        this.getListEtablissement()
        const { countries, departements, communes } = utilsService.currentUserUtils()
        this.departement = departements
        this.ville_commune = communes
        this.pays = countries
      },
    },
  }, //
  mounted() {
    // this.pays = this.listPays
    // this.mois = this.listMois
    // this.annees = this.listAnnees
    

    // this.loadQuartier();
    if (this.inputData) {
      console.log('❌', this.inputData)
      this.infosEnregistrementClients = this.inputData
    } else if (this.$route.params.data) {
      this.infosEnregistrementClients = this.$route.params.data
    } else {
      // this.infos = this.validOutput
    }
  },
  beforeDestroy() {
    unregisterStoreModule(this.requiredStoreModules)
  },

  methods: {
    ...mapActions('etablissements', {
      action_findEtablissement: 'findEtablissement',
      action_fetchEtablissements: 'fetchEtablissements',
      action_getListTypesEtablissement: 'getListTypesEtablissement',
    }),
    getListEtablissement() {
      // laod type etablissement
      let currentEtablissement = null

      const data = { options: { code: 'grand_public' } }

      this.action_getListTypesEtablissement()
        .then(response => {
          // get correct hotel
          response.data.resources.map(element => {
            // console.log('element', element)
            if (element.code === 'hotels') {
              currentEtablissement = element
              // console.log('currentEtablissement', currentEtablissement._id)
            }
          })
          // load etablissements
          if (currentEtablissement) {
            const data = {
              options: { typeEtablissement: currentEtablissement._id },
            }
            this.action_fetchEtablissements(data)
              .then(response => {
                // // console.log('response: -------------- ', response)
                this.listes_hotels = response.data.resources
                // console.log('list-etablissement', response, this.listes_hotels)
              })
              .catch(err => {
                // this.isLoadingTableData = false
                // console.log('error-list-etablissement', err)
              })
          }
        })
        .catch(err => {
          // this.isLoadingTableData = false
          // console.log('error-list-type-etablissement', err)
        })

      return

      this.action_findEtablissement()
        .then(response => {
          this.listes_hotels = response.data.resources
          // console.log('list-etablissement', response, this.listes_hotels)
        })
        .catch(err => {
          // this.isLoadingTableData = false
          // console.log('error-list-etablissement', err)
        })

      this.fetchRoles(data)
        .then(res => {
          const { data } = res
          const { roles } = data
          // console.log('roles', roles)
          if (roles && roles[0] && roles[0]._id) {
            this.form.role = roles[0]._id
            // console.log(roles[0]._id)
            // console.log(this.form)
          } else {
            // console.log('errooooooooooooooooooooooooooor')
          }
        })
        .catch(err => {
          // console.log(err)
        })
    },
    sendinfosEnregistrementClients() {
      this.$refs.FormSectionClientHotels.validate().then(success => {
        if (!success) {
          // eslint-disable-next-line
          // alert('form submitted!')
          // console.log(' ------ this.infosEnregistrementClients: ------------- ', this.infosEnregistrementClients)
          // const form = this.infosEnregistrementClients

          if (this.categorieSocioProAutres) this.infosEnregistrementClients.categorieSocioPro
          if (this.motifPrincipalVoyageAutres) this.infosEnregistrementClients.motifPrincipalVoyage

          const { etablissementId } = utilsService.currentUserUtils()
          const data = {
            ...this.infosEnregistrementClients,
            etablissement: etablissementId,
          }
          console.log('data: ', data)

          this.$emit('onFormSucess', {
            routerParams: { preview: true, data },
          })

          // axios
          //   .post('api/v1/adresses', {
          //   })
          //   .then(response => // console.log('response: ', response))
          //   .catch(error => // console.log(error))
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Champs requis',
              icon: 'TriangleIcon',
              // text: '👋 Chocolate oat cake jelly oat cake candy jelly beans pastry.',
              variant: 'danger',
            },
          })
        }
      })
    },
    onCancel() {
      this.$emit('onCancel', {})
    },
    submitForm() {
      // this.$emit('onFormSuccess', this.infos)
      this.$refs.FormSectionClientHotels.validate().then(success => {
        if (success) {
          // if (this.infos) {
          //   if (this.infos.annee.name) this.infos.annee = this.infos.annee.name
          //   if (this.infos.mois.name) this.infos.mois = this.infos.mois.name
          //   if (this.infos.departement.name) this.infos.departement = this.infos.departement.name
          //   if (this.infos.commune.name) this.infos.commune = this.infos.commune.name
          //   if (this.infos.arrondissement.name) this.infos.arrondissement = this.infos.arrondissement.name
          //   if (this.infos.nationalitePromoteur.name) this.infos.nationalitePromoteur = this.infos.nationalitePromoteur.name

          //   if (this.infos.telephone2 == '') delete this.infos.telephone2
          //   if (this.infos.telephone3 == '') delete this.infos.telephone3
          //   if (this.infos.agrementMinistere == 'non') delete this.infos.referenceAgrement
          // }

          if (this.inputData) {
            this.$emit('onFormSuccess', this.infosEnregistrementClients)
          } else {
            this.$emit('onFormSucess', {
              routerParams: {
                preview: true,
                data: this.infosEnregistrementClients,
              },
            })
          }
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
