<template>
  <div class="mb-5">
    <b-card>
      <h1 class="text-center">
        Veuillez vous assurer que les informations entrées sont correctes puis
        enregistrez le formulaire
      </h1>
    </b-card>
    <b-card class="preview-document">
      <b-row>
        <b-col cols="12">
          <div v-if="infos" class="pl-1 pr-1">
            <!-- renseignement generaux -->

            <div class="customborder1 p-1">
              <!-- <b-row>
                <b-col
                  cols="12"
                  class="text-right"
                >
                  <small class="">(Cliquer sur ce bouton pour modifier la section)</small>
                  <b-img
                    title="Modifier cette section"
                    class="cursor-pointer"
                    width="20"
                    height="20"
                    :src="editIconGreen"
                    @click="editSection('')"
                  />
                </b-col>
              </b-row> -->

              <hr class="my-1" />
              <b-row>
                <!-- <b-col
                  md="6"
                  class="mb-1"
                >
                  Nom de l'établissement : <strong> {{ infos.etablissement }}</strong>
                </b-col> -->

                <b-col md="12" class="mb-1">
                  NPI : <strong>{{ infos.npi }}</strong>
                </b-col>
              </b-row>

              <b-row>
                <b-col md="6" class="mb-1">
                  Date d'arrivée : <strong>{{ infos.dateArrivee }}</strong>
                </b-col>
                <b-col md="6" class="mb-1">
                  Heure : <strong>{{ infos.heureArrivee }}</strong>
                </b-col>
              </b-row>

              <b-row>
                <b-col md="6" class="mb-1">
                  Chmabre n° : <strong>{{ infos.numeroChambre }}</strong>
                </b-col>
                <b-col md="6" class="mb-1">
                  Date de départ : <strong>{{ infos.dateDepart }}</strong>
                </b-col>
              </b-row>

              <b-row>
                <b-col md="6" class="mb-1">
                  Nom : <strong> {{ infos.lastname }}</strong>
                </b-col>

                <b-col md="6" class="mb-1">
                  Prénoms : <strong>{{ infos.firstname }}</strong>
                </b-col>
              </b-row>

              <b-row>
                <b-col md="6" class="mb-1">
                  Nom de la jeune fille :
                  <strong>{{ infos.maidenname }}</strong>
                </b-col>
                <b-col md="6" class="mb-1">
                  Sexe : <strong>{{ infos.sexe }}</strong>
                </b-col>
              </b-row>

              <b-row>
                <b-col md="6" class="mb-1">
                  Date de naissance : <strong>{{ infos.dateNaissance }}</strong>
                </b-col>
                <b-col md="6" class="mb-1">
                  Lieu de naissance : <strong>{{ infos.lieuNaissance }}</strong>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="12" class="mb-1">
                  Nationalité : <strong>{{ infos.nationalite }}</strong>
                </b-col>
              </b-row>
              <hr class="my-1" />

              <!-- Pièce d'identité -->

              <b-row>
                <b-col md="6" class="mb-1">
                  Pièce d'identité :
                  <strong> {{ infos.pieceIdentite.type }}</strong>
                </b-col>

                <b-col md="6" class="mb-1">
                  Numéro de la pièce :
                  <strong>{{ infos.pieceIdentite.numeroPiece }}</strong>
                </b-col>
              </b-row>

              <b-row>
                <b-col md="6" class="mb-1">
                  Délivrée le :
                  <strong>{{ infos.pieceIdentite.delivreLe }}</strong>
                </b-col>
                <b-col md="6" class="mb-1">
                  À : <strong>{{ infos.pieceIdentite.a }}</strong>
                </b-col>
              </b-row>

              <hr class="my-1" />

              <!-- Lieu de résidence habituel -->

              <b-row>
                <b-col md="6" class="mb-1">
                  Adresse : <strong> {{ infos.lieuResidence.adresse }}</strong>
                </b-col>
                <b-col md="6" class="mb-1">
                  Code postal : <strong> {{ infos.lieuResidence.bp }}</strong>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="6" class="mb-1">
                  Ville/Commune :
                  <strong> {{ infos.lieuResidence.villeCommune }}</strong>
                </b-col>
                <b-col md="6" class="mb-1">
                  Département :
                  <strong> {{ infos.lieuResidence.departement }}</strong>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="6" class="mb-1">
                  Pays : <strong> {{ infos.lieuResidence.pays }}</strong>
                </b-col>
                <b-col md="6" class="mb-1">
                  Téléphone : <strong> {{ infos.telephone }}</strong>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="12" class="mb-1">
                  Email : <strong> {{ infos.email }}</strong>
                </b-col>
              </b-row>

              <hr class="my-1" />

              <!-- Autres informations -->

              <b-row>
                <b-col md="6" class="mb-1">
                  Catégorie Socio-Professionnelle :
                  <strong>
                    {{
                      infos.categorieSocioProAutres
                        ? infos.categorieSocioProAutres
                        : infos.categorieSocioPro
                    }}</strong
                  >
                </b-col>

                <b-col md="6" class="mb-1">
                  Mode principal de transport à l'intérieur du pays :
                  <strong>{{ infos.modeTransportInterieurPays }}</strong>
                </b-col>
              </b-row>

              <b-row>
                <b-col md="6" class="mb-1">
                  Motif principal de voyage :
                  <strong>{{
                    infos.motifPrincipalVoyageAutres
                      ? infos.motifPrincipalVoyageAutres
                      : infos.motifPrincipalVoyage
                  }}</strong>
                </b-col>
                <b-col md="6" class="mb-1">
                  Allez-vous percevoir une rémunération d'une entité du
                  pays/lieu visité durant votre séjour ?
                  <strong>{{
                    infos.possibiliteRemuneration === true ? "Oui" : "Non"
                  }}</strong>
                </b-col>
              </b-row>
            </div>
          </div>
        </b-col>
      </b-row>
      <b-row class="px-1 mt-2">
        <b-col md="6">
          <div class="text-left">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="secondary"
              class="col-xl-4"
              @click="edit()"
            >
              Retourner sur le formulaire
            </b-button>
          </div>
        </b-col>
        <b-col md="6">
          <div class="text-right">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="primary"
              class="col-xl-4"
              @click="save()"
            >
              <span v-if="isSavingForm" class="d-flex align-items-center"
                ><div class="mr-2"><b-spinner /></div>

                <span class=""> Validation du formulaire... </span>
              </span>
              <span v-if="!isSavingForm"> Enregistrer les informations </span>
            </b-button>
          </div>
        </b-col>
      </b-row>
    </b-card>

    <!-- modal backdrop -->
    <b-modal
      :ref="sectionEditionModalRef"
      scrollable
      hide-footer
      no-close-on-backdrop
      content-class="shadow"
      title="Modifier cette section"
      size="xl"
    >
      <!-- <b-card-text>
        <span>We've added the utility class</span>
        <code>'shadow'</code>
        <span>to the modal content for added effect.</span>
      </b-card-text> -->
      <component
        :is="sectionToEditComponent"
        :input-data="sectionToEditData"
        :show-edition-buttons="true"
        @onFormSuccess="onEditionFormSuccess($event)"
        @onCancel="hideSectionEditionModal"
      />
    </b-modal>
  </div>
</template>

<script>
import {
  BCol,
  BRow,
  BButton,
  BCard,
  BCardHeader,
  BCardBody,
  BFormRadioGroup,
  BListGroup,
  BListGroupItem,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BFormRadio,
  BForm,
  BCardText,
  VBTooltip,
  BImg,
  BSpinner,
  BModal,
} from "bootstrap-vue";
import { heightTransition } from "@core/mixins/ui/transition";
import Ripple from "vue-ripple-directive";
// import axios from 'axios'
import vSelect from "vue-select";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { mapActions } from "vuex";
import AppLogo from "@/components/AppLogo.vue";
import localstorageService from "@/services/localstorage/localstorage.service";
import utilsService from "@/services/utils/utils.service";
import formulaireStoreModule from "@/store/formulaire";
import {
  registerStoreModule,
  unregisterStoreModule,
} from "@/helpers/vuex-utils";
import FormHotelClient from "./sections/FormHotelClient.vue";

export default {
  components: {
    BCol,
    BRow,
    BButton,
    BCard,
    BCardHeader,
    BCardBody,
    BFormRadioGroup,
    BListGroup,
    BListGroupItem,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BFormRadio,
    BForm,
    BCardText,
    BSpinner,
    vSelect,
    ToastificationContent,
    AppLogo,
    VBTooltip,
    BImg,
    BModal,
    FormHotelClient,
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  setup() {
    const requiredStoreModules = [
      { path: "formulaire", module: formulaireStoreModule },
    ];
    // Register module
    registerStoreModule(requiredStoreModules);

    return { requiredStoreModules };
  },
  data() {
    return {
      isSavingForm: false,

      infos: null,

      preview: null,
      isProcessingProvidedInput: true,
      sectionEditionModalRef: "edit-section-modal",
      sectionToEditComponent: null,
      sectionToEditData: null,
      editIconGreen: require("@/assets/images/icons/edit vert.png"),
      editIcon: require("@/assets/images/icons/edit.png"),
      currentUserId: localstorageService.getUserId(),
    };
  },
  computed: {
    layoutContentRenderer() {
      const rendererType = this.$route.meta.contentRenderer;
      if (rendererType === "sidebar-left") {
        return "layout-content-renderer-left";
      }
      if (rendererType === "sidebar-left-detached") {
        return "layout-content-renderer-left-detached";
      }
      return "layout-content-renderer-default";
    },
  },
  beforeDestroy() {
    unregisterStoreModule(this.requiredStoreModules);
  },
  mounted() {
    if (this.$route.params.data && this.$route.params.preview) {
      const { data, isEditionOperation } = this.$route.params;
      this.infos = {
        ...data,
        // ...(isEditionOperation && { editor: this.currentUserId }),
      };

      console.log("infos", data, this.infos);
      if (isEditionOperation) this.infos.editor = this.currentUserId;
      this.preview = this.$route.params.preview;
    } else {
      // this.infos = validInput
      this.$router.push({
        name: "formulaires-create",
        params: { slug: this.$route.params.slug },
      });
    }
  },
  methods: {
    ...mapActions("formulaire", {
      addFormulaire: "addFormulaire",
      updateFormulaire: "updateFormulaire",
    }),
    // ---------------------------------------------------------------------
    onEditionFormSuccess(evt) {
      this.infos = { ...this.infos, ...evt };
      this.hideSectionEditionModal();
    },
    // eslint-disable-next-line consistent-return
    getSectionToEditComponent(section) {
      return "FormHotelClient";
    },
    save() {
      const { currentUserId, etablissementDetails, userRoleId } =
        utilsService.currentUserUtils();
      this.isSavingForm = true;
      const { isEditionOperation } = this.$route.params;
      const successMessage = !isEditionOperation
        ? "Formulaire enregistré avec succès"
        : "Opération réussie";
      const serviceMethode = isEditionOperation
        ? "updateFormulaire"
        : "addFormulaire";
      const { _id } = this.infos;
      if (this.infos.npi === null || this.infos.npi === "")
        delete this.infos.npi;
      delete this.infos._id;

      if (this.infos.maidenname == (null || "")) delete this.infos.maidenname;
      if (this.infos.categorieSocioProAutres) {
        this.infos.categorieSocioPro = this.infos.categorieSocioProAutres;
      }
      if (this.infos.motifPrincipalVoyageAutres) {
        this.infos.motifPrincipalVoyage = this.infos.motifPrincipalVoyageAutres;
      }
      delete this.infos.categorieSocioProAutres;
      delete this.infos.motifPrincipalVoyageAutres;

      let userData = localstorageService.getUserData();
      console.log('userData: ', userData)
      if (isEditionOperation) data.data.editor = this.currentUserId;
      if (currentUserId) {
        this.infos.auteur = currentUserId;
        this.infos.auteurRole = userData.role._id;
      } else {
        delete this.infos.auteur;
      }

      const data = {
        formType: "forms-clients-hotels",
        ...(isEditionOperation && { id: _id }),
        data: {
          ...this.infos,
          npi: `${this.infos.npi}`,
          // ...(!isEditionOperation && { editor: this.currentUserId }),
        },
      };
      if (isEditionOperation) data.data.editor = this.currentUserId;

      if (etablissementDetails) {
        if (etablissementDetails._id)
          data.data.etablissement = etablissementDetails._id;
          data.data.departement = etablissementDetails.departement;
          data.data.commune = etablissementDetails.commune;
          data.data.arrondissement = etablissementDetails.arrondissement;
          data.data.quartier = etablissementDetails.quartier;
        if (etablissementDetails.secteur)
          data.data.secteur = etablissementDetails.secteur;
      }

      console.log('data: ', data)
      console.log('serviceMethode::: ', serviceMethode);
      // return
      setTimeout(() => {
        this[serviceMethode](data)
          .then((response) => {
            // console.log('============ response create form: ===========', response)
            // return

            if (response.status === 200) {
              if (
                this.$store.state.auth &&
                this.$store.state.auth.isAuthenticated
              ) {
                // this.$router.push({
                //   name: 'formulaires-list',
                //   params: { slug: this.$route.params.slug },
                // })

                let selectedForm = utilsService.getFormulaireBySlug(
                  this.$route.params.slug
                );
                // console.log('selectedForm: 🔵🔴🔵🔴 ', selectedForm)
                const { params } = selectedForm.createRouteProps;
                this.$router.push({
                  ...selectedForm.createRouteProps,
                  params: {
                    ...params,
                  },
                });

                this.$toast({
                  component: ToastificationContent,
                  position: "top-right",
                  props: {
                    title: successMessage,
                    icon: "CheckIcon",
                    variant: "success",
                    // text: 'Bienvenue !',
                  },
                });
              } else {
                this.$router.push({
                  name: "home-public",
                });
                this.$toast({
                  component: ToastificationContent,
                  position: "top-right",
                  props: {
                    title: successMessage,
                    icon: "CheckIcon",
                    variant: "success",
                    // text: 'Bienvenue !',
                  },
                });
              }
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Une erreur est suvenue, veuillez rééssayer",
                  icon: "AlertTriangleIcon",
                  variant: "danger",
                },
              });
            }
            this.isSavingForm = false;
          })
          .catch((error) => {
            this.isSavingForm = false;
            this.$toast({
              component: ToastificationContent,
              props: {
                title:
                  error.message || "Une erreur est suvenue, veuillez rééssayer",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
          });
      }, 2000);
    },
    showSectionEditionModal() {
      this.$refs[this.sectionEditionModalRef].show();
    },
    hideSectionEditionModal() {
      this.$refs[this.sectionEditionModalRef].hide();
    },
    editSection(section) {
      this.sectionToEditComponent = this.getSectionToEditComponent(section);
      this.sectionToEditData = JSON.parse(JSON.stringify(this.infos));

      this.showSectionEditionModal();
    },
    edit() {
      console.log("this.infos ::::::::::::: ", this.infos);
      this.$router.push({
        name: "formulaires-create",
        params: { slug: this.$route.params.slug, data: this.infos },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@core/scss/base/themes/bordered-layout.scss";

.customborder1 {
  border: 1px solid rgba(0, 0, 0, 0.3);
  // padding: 15px 35px;
}
.customborder2 {
  border: 1px solid rgba(0, 0, 0, 0.3);
  // padding: 0 35px 15px;
}

table {
  td,
  th,
  tr {
    text-align: left;
  }
}
thead {
  th {
    background-color: #0e6258 !important;
    color: white;
  }
}
</style>
